.titles[data-v-9db670] {
  font-family: "Roboto Flex";
  font-size: 4.5rem;
  line-height: 1;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}@media (min-width: 640px) {.titles[data-v-9db670] {
    font-size: 4.5rem;
    line-height: 1;
  }
}@media (min-width: 1280px) {.titles[data-v-9db670] {
    font-size: 6rem;
    line-height: 1;
  }
}.titles[data-v-9db670] {
  user-select: none;
}#website[data-v-9db670] {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}#development[data-v-9db670] {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}#recipe[data-v-9db670] {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}h1[data-v-9db670] {
  font-family: "Roboto Flex";
  font-variation-settings: "wght" 1000, "wdth" 151, "opsz" 13.44, "GRAD" -0.5, "slnt" 0, "XTRA" 468.6, "XOPQ" 96.56, "YOPQ" 78.9, "YTLC" 514.56, "YTUC" 711.28, "YTAS" 749.45, "YTDE" -230.48, "YTFI" 737.84;
}